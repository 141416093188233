import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "source-material" }
const _hoisted_2 = { class: "content scroll-bar-hidden" }
const _hoisted_3 = { class: "card" }
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 1,
  style: {"height":"100%","display":"flex","align-items":"center"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_timetable_title = _resolveComponent("timetable-title")!
  const _component_close_icon = _resolveComponent("close-icon")!
  const _component_a_image = _resolveComponent("a-image")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_empty_no_lesson = _resolveComponent("empty-no-lesson")!
  const _component_goto_lesson = _resolveComponent("goto-lesson")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createVNode(_component_timetable_title, null, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode("自定义素材")
        ])),
        _: 1
      }),
      _createVNode(_component_close_icon)
    ]),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.sourceList.length > 0)
        ? (_openBlock(), _createBlock(_component_a_row, {
            key: 0,
            type: "flex",
            align: "middle"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sourceList, (i, k) => {
                return (_openBlock(), _createBlock(_component_a_col, {
                  span: 6,
                  key: k
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_3, [
                      (i.sourceType === _ctx.ESourceType.picture)
                        ? (_openBlock(), _createBlock(_component_a_image, {
                            key: 0,
                            src: i.url
                          }, null, 8, ["src"]))
                        : (i.sourceType === _ctx.ESourceType.video)
                          ? (_openBlock(), _createElementBlock("video", {
                              key: 1,
                              src: i.url,
                              controls: "",
                              controlsList: "nodownload"
                            }, null, 8, _hoisted_4))
                          : _createCommentVNode("", true)
                    ])
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          }))
        : (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_empty_no_lesson, {
              "img-path": require('@/assets/image/timetable/pic_nosucai@2x.png')
            }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createElementVNode("span", { style: {"color":"#FFF"} }, "暂无自定义素材哦~", -1)
              ])),
              _: 1
            }, 8, ["img-path"])
          ]))
    ]),
    _createVNode(_component_goto_lesson)
  ]))
}