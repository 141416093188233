import {reactive, toRefs} from "vue";
import service from "@/utils/axios";
import {useRoute} from "vue-router";

export enum ESourceType {
  video = "2",
  picture = "1",
}

export interface ISourceItem {
  id?:any;
  url: string|null; // 视频地址
  sourceType: string;
}

export interface ISourceMaterial {
  sourceList: Array<ISourceItem>;
}

export function sourceMaterialBill() {
  const route = useRoute();
  let stateRef = reactive(<ISourceMaterial>{
    sourceList: [],
  });

  let refData = toRefs(stateRef)

  const init = async () => {
    await getMaterial()
  }

  const getMaterial = async ()=>{
    const r:any = await service.post('/dwart/an_teaching/classCourse/findTeacherTopicWare', {
      topicId: route.query.topicId,
      classId: route.query.classId,
    })

    stateRef.sourceList = r.map(v=>{
      return {
        id: v.id,
        url: v.sourceUrl,
        sourceType: v.resourceType
      }
    })

  }

  return {
    refData,
    init,
  }
}
