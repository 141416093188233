
import TimetableTitle from "@/components/timetable/TimetableTitle.vue";
import CloseIcon from "@/components/system/CloseIcon.vue";
import {defineComponent, onMounted} from "vue";
import {Col, Image, Row} from "ant-design-vue";
import {ESourceType, sourceMaterialBill} from "@/views/timetable/myTimetable/SourceMaterial/SourceMaterial";
import EmptyNoLesson from "@/components/timetable/EmptyNoLesson.vue";
import GotoLesson from "@/components/timetable/GotoLesson.vue";

export default defineComponent({
  name: "SourceMaterial",
  components: {
    GotoLesson,
    EmptyNoLesson,
    CloseIcon,
    TimetableTitle,
    ARow: Row,
    ACol: Col,
    AImage: Image,
  },
  setup() {
    const {
      refData,
      init,
    } = sourceMaterialBill();

    onMounted(async ()=>{
      await init();
    })

    return {
      ESourceType,
      ...refData,
    }
  },
  data() {
    return {}
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {

    },
  },
})
